<template>
  <div>
    <!-- 头部样式 左侧为功能title  右侧为业务按钮 -->
    <head-layout :head-btn-options="headBtnOptions"
                 :head-title="$t('cip.plat.sys.international.title.indexHeadTitle')"
                 @head-add="handleAdd"
                 @head-romve="headRomve"></head-layout>
    <!-- 对表单数据的搜索样式 -->
    <div class="headers">
      <grid-head-layout ref="searchFrom" :search-columns="searchColumns" v-model="searchForm"
                        @grid-head-search="searchChange" @grid-head-empty="searchReset">
      </grid-head-layout>
    </div>
    <!-- 表格 -->
    <grid-layout ref="gridLayOut"
                 :tableOptions="tableOptions"
                 :table-data="dataParent"
                 :table-loading="loading"
                 :data-total="dataTotal"
                 :page="page"
                 @page-size-change="onLoad"
                 @page-current-change="onLoad"
                 @page-refresh-change="onLoad"
                 @gird-handle-select-click="selectionChange"
                 :gridRowBtn="gridRowBtn"
                 @grid-edit="handleEdit"
                 @grid-view="rowView"
                 class="mainBox"
    ></grid-layout>

  </div>
</template>
<script>
import HeadLayout from "@/views/components/layout/head-layout"
import GridLayout from "@/views/components/layout/grid-layout";
export default {
  components: {HeadLayout, GridLayout},
  name: "index",
  data() {
    return {
      //表格行按钮
      gridRowBtn: [
        // {
        //   label: this.$t("cip.cmn.btn.editBtn"),
        //   emit: "grid-edit",
        //   type: "text",
        //   icon: ""
        // },
        {
          label: this.$t("cip.cmn.btn.viewBtn"),
          emit: "grid-view",
          type: "text",
          icon: ""
        }
      ],
      searchForm: [],
      selectionList: [],
      dataParent: [],//表格数据
      dataTotal: 0,//总数据量
      tableOptions: {
        menuWidth: 250,
        column: [
          {
            label: this.$t('cip.plat.sys.international.field.name'),
            headerAlign: "center",
            prop: "name",
          },
          {
            label: this.$t('cip.plat.sys.international.field.content'),
            headerAlign: "center",
            prop: "content",
          },
          {
            label: this.$t('cip.plat.sys.international.field.url'),
            headerAlign: "center",
            prop: "url",
          },
        ]
      },
      form: {},
      query: {},
      loading: true,
      data: []
    }
  },
  computed: {
  },
  methods: {
    //修改
    handleEdit(row) {
      console.log(row)
      const modulesFilesen = require.context("@/lang", true, /\.js$/);
      const modulesen = modulesFilesen.keys().reduce((modules, modulePath) => {
        if(row.url==modulePath){
          const moduleName = modulePath.replace(/^.\/(.*)\.js/, "$1");
          const value = modulesFilesen(modulePath);
          modules[moduleName] = value;
        }
        return modules;
      }, {});

      console.log(modulesen,"modulesen")
      var replace = row.content.replace(".js","");
      console.log(replace)
      let content= JSON.stringify(modulesen[replace].default,null,'\t')

      this.$router.push({
        path: '/international/add',
        query: {
          id: row.id,
          type: 'edit',
          data: content,
        }
      })
    },
    rowView(row) {
      console.log(row)
      const modulesFilesen = require.context("@/lang", true, /\.js$/);
      const modulesen = modulesFilesen.keys().reduce((modules, modulePath) => {
        if(row.url==modulePath){
          const moduleName = modulePath.replace(/^.\/(.*)\.js/, "$1");
          const value = modulesFilesen(modulePath);
          modules[moduleName] = value;
        }
        return modules;
      }, {});

      console.log(modulesen,"modulesen")
      var replace = row.content.replace(".js","");
      console.log(replace)
      let content= JSON.stringify(modulesen[replace].default,null,'\t')
      this.$router.push({
        path: '/international/add',
        query: {
          id: row.id,
          type: 'view',
          data: content,
        }
      })
    },
    onLoad() {
      const fileNames = require.context('@/lang', true, /.js/)
      console.log('fileNames:', fileNames.keys())
      let List = [
        {
          name: '暂无',
          content: '',
          url: ''
        }, {
          name: '暂无',
          content: '',
          url: ''
        }, {
          name: '暂无',
          content: '',
          url: ''
        }, {
          name: '暂无',
          content: '',
          url: ''
        }, {
          name: '暂无',
          content: '',
          url: ''
        }
      ];
      fileNames.keys().forEach((item, index) => {
        List[index].content = item.replace("./", "")
        List[index].url = item
      })
      this.dataParent=List
      console.log(List, '188')
      this.loading = false;
    },
  },
  created() {
    this.onLoad();
  }
}
</script>
<style lang="scss" scoped>
//::v-deep .mainBox .el-table {
//  max-height: 56vh !important;
//}
//::v-deep .el-tree {
//  height: 680px !important;
//}
</style>
